import { Menu, X,ChevronDown } from "lucide-react";
import { IoMdLogOut } from "react-icons/io"; // Import the IoMdLogOut icon
import { useState } from "react";
import CompanyLogo from '../assets/newlogos/PNG-9-HORIZNTAL-ND-LOGO-WITHOUT-BG.png'
import scheduleCallIcon from "../assets/images/call.png";
import loginIcon from "../assets/icons/login.png";
import { navItems } from "../constants/index";
import Login from './Login'; // Import the Login component

const Navbar = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false); // State to handle modal visibility
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false); // State for mobile dropdown

  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const toggleModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    setShowLoginModal(false);
  };

  // Toggle services dropdown on mobile
  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!servicesDropdownOpen);
  };


  return (
      <nav className="top-0 py-3 px-4 sticky bg-white z-10">
        <div className="px-2 mx-auto relative lg:text-sm">
          {/* for pc screen */}
          <div className="flex justify-between items-center">
            <a href="/" className="flex items-center flex-shrink-0">
              <img src={CompanyLogo} alt="Logo" className="w-[179.96px] h-[120px]"/>
            </a>
            <ul className="hidden lg:flex ml-8 space-x-6">
              {navItems.map((item, index) => (
                  <li
                      key={index}
                      className={`relative text-[15px] px-2 py-1 font-semibold text-[#4F6370] rounded-lg hover:bg-[#0EA28880]/50 hover:text-[#000000] transition duration-300 ${
                          item.submenu ? 'group' : ''
                      }`}
                  >
                    <a href={item.href}>{item.label}</a>

                    {/* Dropdown for Services */}
                    {item.submenu && (
                        <ul className="absolute  left-0 mt-2 hidden group-hover:block bg-white shadow-lg rounded-lg p-4 space-y-2 z-10 w-96">
                          {item.submenu.map((submenuItem, subIndex) => (
                              <li key={subIndex} className="hover:text-[#0EA288] transition duration-300">
                                <a href={submenuItem.href} className="block px-4 py-2 hover:bg-[#0EA28880] rounded-lg">
                                  {submenuItem.label}
                                </a>
                              </li>
                          ))}
                        </ul>
                    )}
                  </li>
              ))}
            </ul>
            <div className="hidden lg:flex justify-center space-x-10 items-center">
              {/* Schedule Call */}
              <div className="schedule-icon-button font-semibold py-2 px-3 rounded-lg">
                <a href="https://calendly.com/fahadqureshi/meeting-request" target="_blank"
                   className="flex items-center">
                  <img width={35} height={35} src={scheduleCallIcon} alt="Schedule Call Icon" className="icon mr-2"/>
                  Schedule Call
                </a>
              </div>
              {/* Login/Logout Icon */}
              <div>
                {sessionStorage.getItem('user') ? (
                    <IoMdLogOut
                        onClick={handleLogout}
                        className="cursor-pointer text-[#0EA288] text-2xl w-[40px] h-[40px]"
                    />
                ) : (
                    <img width={50} height={50} src={loginIcon} alt="Login Icon" className="mr-2 cursor-pointer" onClick={toggleModal}/>
                )}
              </div>
            </div>
            {/* for mobile screen */}
            <div className="lg:hidden md:flex flex-col justify-end">
              <button onClick={toggleNavbar}>
                {mobileDrawerOpen ? <X/> : <Menu/>}
              </button>
            </div>
          </div>
          {mobileDrawerOpen && (
              <div
                  className="fixed left-0 z-20 bg-[#4F6370] w-full p-8 flex flex-col justify-center items-center lg:hidden">
                <ul>
                  {navItems.map((item, index) => (
                      <li key={index} className="py-4 text-[15px] px-2 font-semibold text-white">
                        {item.submenu ? (
                            <>
                              <div onClick={toggleServicesDropdown}
                                   className="flex justify-between items-center cursor-pointer">
                                {item.label}
                                {item.submenu &&
                                    <ChevronDown className={`ml-2 ${servicesDropdownOpen ? 'rotate-180' : ''}`}/>}
                              </div>

                              {/* Mobile dropdown menu */}
                              {item.submenu && servicesDropdownOpen && (
                                  <ul className="pl-4 mt-2">
                                    {item.submenu.map((submenuItem, subIndex) => (
                                        <li key={subIndex} className="py-1 text-white">
                                          <a href={submenuItem.href}>{submenuItem.label}</a>
                                        </li>
                                    ))}
                                  </ul>
                              )}
                            </>
                        ) : (
                            <a href={item.href}>{item.label}</a>
                        )}
                      </li>
                  ))}
                </ul>
                <div className="hidden sm:flex space-x-6">
                  {/* Schedule Call */}
                  <a href="https://calendly.com/fahadqureshi/meeting-request" target="_blank"
                     className="bg-[#0EA288] text-[#FFFFFF] font-semibold py-2 px-3 rounded-lg flex items-center">
                    <img src={scheduleCallIcon} alt="Schedule Call Icon" className="mr-2"/>
                    Schedule Call
                  </a>
                  {/* Login Icon */}
                  <div>
                    {sessionStorage.getItem('user') ? (
                        <IoMdLogOut
                            onClick={handleLogout}
                            className="cursor-pointer text-[#0EA288] text-2xl mt-2 mr-2 w-[40px] h-[40px]"
                        />
                    ) : (
                        <img src={loginIcon} alt="Login Icon" onClick={toggleModal}/>
                    )}
                  </div>
                </div>
              </div>
          )}
        </div>
        <Login showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal}/>
      </nav>
  );
};

export default Navbar;
