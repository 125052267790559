import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head
import 'aos/dist/aos.css';
import Navbar from './components/Navbar';
import MyHero from './components/MyHero';
import Footer from './components/Footer';
import AttendancePage from './pages/AttendancePage';
import UpdateDaily from './pages/Updatepage';
import SprintTicket from './pages/Sprintpage';
import AddProject from './pages/AddProject';
import AllProjects from './components/AllProject';
import EditProject from './components/EditProject';
import AddSprint from './pages/AddSprint';
import AllSprints from './components/AllSprint';
import TicketSprint from './components/TicketSprint';
import Team from './components/Team';
import AllCustomer from './pages/AllCustomer';
import GenerateSalarySlip from './components/GenerateSS';
import Login from './components/Login';
import Admindashboard from './pages/AdminDashboard';
import LeaveRequest from './pages/LeaveRequest';
import Report from './pages/Reports';
import GenerateInvoice from './components/GenerateInvoice';
import UsersManagement from './pages/UserManagement';
import Projects from "./components/Projects";
import Solution from "./components/Solution";
import Expertise from "./components/Expertise";
import Testimonials from "./components/Testimonials";
import RecentProjects from "./components/RecentProjects";
import Salaries from './pages/Salaries';
import UserDetails from './components/UserDetails';
import EditUser from './components/EditUser';
import AddUser from './components/AddUser';
import AllProjectPage from "./components/AllProjectPage";
import ProjectDetailPageOne from "./components/ProjectDetailPageOne";
import Contact from "./components/contact";
import CummunityJoin from "./components/CummunityJoin";
import Careers  from "./components/Careers";
import OfferDetailPageOne from "./components/OfferDetailPageOne"
import ContactPage from "./components/ContactPage";
import { FiX } from 'react-icons/fi'; // Import the X (close) icon from react-icons
import { jobListings } from './components/Careers';
import InvoicePage from './pages/InvoicePage';
import WebDevelopmentPage from "./components/WebDevelopmentPage";
import MobileApplicationPage from "./components/MobileApplicationPage";
import GenerativeAIPage from "./components/GenerativeAIPage";
import CustomSoftwareDevelopmentPage from "./components/CustomSoftwareDevelopmentPage";
import QATestingAndAutomationsPage from "./components/QATestingAndAutomationsPage";
import UIUXDesiging from "./components/UIDesigningPage";
import DeploymentPage from "./components/DeploymentPage";
import SoftwareArchitectDesignPage from "./components/SoftwareArchitectDesignPage";


// Modal component
const JobModal = ({ isOpen, closeModal }) => {
    const navigate = useNavigate();

    if (!isOpen) return null;

    const handleGoToJobs = () => {
        closeModal(); // Close the modal first
        navigate('/careers'); // Navigate to the Careers page
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-white p-6 rounded-lg shadow-lg text-center">
                {/* Close Icon */}
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={closeModal}
                >
                    <FiX size={24} />
                </button>

                <div className="d-flex flex-center justify-center justify-content-center flex">
                    <img style={{width: '10rem', height: '10rem'}} src="/apply.gif" alt=""/>
                </div>
                <h2 className="text-2xl font-semibold mb-4 text-[#4F6370]">Checkout the latest Job Opportunities!</h2>
                <p className="mb-4 text-[#4F6370]">Are you ready to take the next step in your career?</p>
                <button
                    className="bg-[#0EA288] text-white font-semibold py-2 px-4 rounded-lg hover:bg-[#0EA288]/90 transition duration-300"
                    onClick={handleGoToJobs}
                >
                    View Jobs at NovaSync Dynamics
                </button>
            </div>
        </div>
    );
};


const App = () => {
    const [projects, setProjects] = useState([]);
    const [sprints, setSprints] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const activeJobs = jobListings.filter(job => job.active);
    useEffect(() => {
        if (activeJobs.length === 0) {
            return;
        }
        const modalShown = localStorage.getItem('modalShown');
        if (!modalShown) {
            const timer = setTimeout(() => {
                setIsModalOpen(true);
            }, 3000);
            return () => clearTimeout(timer);
        } else {
        }
    }, [activeJobs]);


    useEffect(() => {
        const modalShown = localStorage.getItem('modalShown');

        if (!modalShown) {
            // Show modal after 3 seconds if it hasn't been shown
            const timer = setTimeout(() => {
                setIsModalOpen(true);
            }, 3000);

            // Cleanup the timer if the component is unmounted
            return () => clearTimeout(timer);
        }
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
        // Store a flag in localStorage so the modal doesn't show again
        localStorage.setItem('modalShown', 'true');
    };

    useEffect(() => {
        AOS.init({
            duration: 800, // Animation duration in milliseconds
        });
    }, []);


    const addSprint = (newSprint) => {
        setSprints([...sprints, newSprint]);
    };

    const addProject = (project) => {
        setProjects([...projects, project]);
    };

    const addCustomer = (customer) => {
        setCustomers([...customers, customer]);
    };

    const updateProject = (updatedProject) => {
        setProjects(projects.map(project => project.key === updatedProject.key ? updatedProject : project));
    };

    useEffect(() => {
        AOS.init({
            duration: 800, // Animation duration in milliseconds
        });
    }, []);

    return (
        <BrowserRouter>
            <Helmet>
                {/* Google Analytics (gtag.js) */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-W6X718DMFR"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-W6X718DMFR');
                    `}
                </script>
            </Helmet>
            {activeJobs.length > 0 && <JobModal isOpen={isModalOpen} closeModal={closeModal} />}
            <Routes>
                <Route path="/" element={
                    <>
                        <Navbar />
                        <MyHero />
                        <Projects />
                        <Solution/>
                        <Expertise/>
                        <Testimonials/>
                        <Team/>
                        <RecentProjects/>
                        <Contact />
                        <ContactPage />
                        <Footer/>
                    </>
                } />

                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Admindashboard />} />
                <Route path="/attendance" element={<AttendancePage />} />
                <Route path="/leaverequest" element={<LeaveRequest />} />
                <Route path="/generate-salary-slip" element={<GenerateSalarySlip />} />
                <Route path="/generated-page" element={<GenerateInvoice/>} />
                <Route path='/user-management' element={<UsersManagement users={users} setUsers={setUsers} />} />
                <Route path='/add-user' element={<AddUser users={users} setUsers={setUsers} />} />
                <Route path="/sprint-ticket" element={<SprintTicket />} />
                <Route path="/add-project" element={<AddProject addProject={addProject} />} />
                <Route path="/all-projects" element={<AllProjects projects={projects} />} />
                <Route path="/all-projects-page" element={<AllProjectPage/>} />
                <Route path="/web-development" element={<WebDevelopmentPage/>} />
                <Route path="/mob-application-development" element={<MobileApplicationPage/>} />
                <Route path="/generative-ai-development" element={<GenerativeAIPage/>} />
                <Route path="/custom-development-application" element={<CustomSoftwareDevelopmentPage/>} />
                <Route path="/QA-testing" element={<QATestingAndAutomationsPage/>} />
                <Route path="/UI-UX-Desiging" element={<UIUXDesiging/>} />
                <Route path="/deployment" element={<DeploymentPage/>} />
                <Route path="/software-architecture-design" element={<SoftwareArchitectDesignPage/>} />
                <Route path="/projects/:projectName" element={<ProjectDetailPageOne />} />
                <Route exact path="/" component={<RecentProjects />} />
                {/*<Route path="/projects/:projectId" element={<ProjectDetailPageOne />} />*/}
                <Route path="/edit-project/:key" element={<EditProject projects={projects} updateProject={updateProject} />} />
                <Route path="/add-sprint/:key" element={<AddSprint addSprint={addSprint} />} />
                <Route path="/all-sprints/:key" element={<AllSprints sprints={sprints} />} />
                <Route path="/ticket-sprint" element={<TicketSprint />} />
                <Route path="/all-customer" element={<AllCustomer customers={customers} addCustomer={addCustomer} />} />
                <Route path="/reports" element={<Report />} />
                <Route path="/update-page" element={<UpdateDaily />} />
                <Route path="/salaries" element={<Salaries />} />
                <Route path="/user-details" element={<UserDetails />} />
                <Route path="/edit-user" element={<EditUser />} />
                <Route path="/job/:jobTitle" element={<OfferDetailPageOne />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/invoice/:id" element={<InvoicePage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
